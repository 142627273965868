<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <!--    Form Dialog-->
    <v-dialog
      v-model="dialog"
      max-width="400px"
    >
      <v-card
        class="mx-auto"
      >
        <v-toolbar flat>
          <v-toolbar-title class="grey--text">
            <span class="text-h3">{{ formTitle }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="close"
          >
            <v-icon class="red--text">
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="editedItem.name"
                  prepend-inner-icon="mdi-home-city"
                  placeholder="Facility Name"
                  outlined
                  label="Facility Name"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  v-model="editedItem.facilityCategory"
                  outlined
                  :items="facilityCats"
                  prepend-inner-icon="mdi-arrange-send-to-back"
                  item-text="name"
                  item-value="_id"
                  label="Select Facility Category"
                  single-line
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  v-model="editedItem.branch"
                  outlined
                  :items="branchList"
                  prepend-inner-icon="mdi-map-marker"
                  item-text="name"
                  item-value="_id"
                  label="Select Branch"
                  single-line
                  @keyup.enter="onAddFacility"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="grey darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            :loading="loader"
            color="primary"
            @click="onAddFacility"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    Form Dialog End-->
    <!--    Delete Dialog Start-->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete this client?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    Delete Dialog end-->

    <!--    All Dialogs End-->
    <!--    Base Material Start -->
    <div v-if="fetchup">
      <v-skeleton-loader
        type="table-heading, list-item-two-line, image, table-tfoot"
      />
    </div>
    <base-material-card
      v-else
      color="black"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">
          <v-row class="align-content-md-space-between">
            <v-col
              cols="12"
              md="9"
              sm="6"
            >
              <div class="text-h3 font-weight-light">
                <v-icon
                  left
                  class="primary--text"
                >
                  mdi-home-city
                </v-icon> Manage Facilities <i class="text-caption">Total: <b>{{ total }}</b></i>
              </div>
              <div class="text-subtitle-1 font-weight-light">
                Please manage all Facilities
              </div>
            </v-col>
            <v-col
              md="3"
              sm="6"
              style="text-align:right"
            >
              <v-btn
                color="primary"
                dark
                outlined
                class="mb-2 subheading font-weight-bold"
                @click="openDialog"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                Add Facility
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
      <v-data-table
        :headers="headers"
        :items="facilities"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            small
            text
            class="grey--text"
            @click="editItem(item)"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-pencil
            </v-icon>
            Edit
          </v-btn>
          <v-btn
            small
            text
            class="red--text"
            @click="deleteItem(item)"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-delete
            </v-icon>
            Delete
          </v-btn>
        </template>
        <template v-slot:no-data>
          <v-btn
            small
            text
            color="primary--text"
            @click="fetchFacilities"
          >
            <v-icon left>
              mdi-refresh
            </v-icon>Reset
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
    <!--    Base Material End-->
  </v-container>
</template>
<script>
  /* eslint-disable */
  const axios = require('axios')
  import { mapGetters } from 'vuex'

  export default {
    name: 'ManageFacilities',
    data: () => ({
      menu: false,
      loader:false,
      total:0,
      dialog: false,
      fetchup: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Facility Name',
          align: 'start',
          sortable: false,
          value: 'name',
          class:'subheading'
        },
        { text: 'Category', value: 'category' },
        { text: 'Branch', value: 'branchName' },
        // { text: 'Location', value: 'location' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
      facilities: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        facilityCategory: null,
        location:'',
      },
      defaultItem: {
        name: '',
        facilityCategory: null,
        location:'',
      },
    }),

    computed: {
      ...mapGetters(['getPackages','getBranchList']),
      formTitle () {
        return this.editedIndex === -1 ? 'Add New Faciltiy' : 'Edit Facility'
      },
      facilityCats(){
        return this.$store.getters.getFacilityCategories;
      },
      branchList(){
        // return this.$store.getters.getBranchList;
        return this.$store.getters.getBranchList;
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    mounted () {
      let self = this;
      this.fetchFacilities()

    },
    created(){
      let self = this;
      setTimeout(()=>{
        self.$store.dispatch('fetchFacilityCategories')
        self.$store.dispatch('fetchBranchList', this.userData.company._id)
      },2000)
    },

    methods: {
      openDialog(){
        this.dialog = true;
      },
      fetchFacilities () {
        this.fetchup = true
        const self = this
        let dict = [];
        axios.get(this.apiSet.facilities)
          .then(response => {
            const obj = response.data.facilities
            for (let key = 0, len = obj.length; key < len; key++) {
              dict.push(
                {_id: obj[key]._id,
                  name: obj[key].name,
                  category: obj[key].facilityCategory.name,
                  facilityCategory:obj[key].facilityCategory,
                  company:obj[key].company._id,
                  branch:obj[key].branch,
                  branchName:obj[key].branch.name,
                  location:obj[key].location,
                },
              )
            }
            console.log(obj);
            self.facilities = dict
            self.total = obj.length
            self.fetchup = false
          }).catch(
          e => {
            self.fetchup = false
            console.log({ rerror: e });
          },
        )
      },
      onAddFacility () {
        if (this.editedIndex > -1) {
          this.updateFacility()
        } else {
          const self = this
          // this.$validator.validateAll().then((result) => {
          //   if (result) {
          this.loader = true
          const catData = this.editedItem

          axios.post(this.apiSet.facilities, {
            name: catData.name,
            facilityCategory: catData.facilityCategory,
            company: this.userData.company._id,
            branch: this.userData.branch._id,
            location: this.userData.branch.name,
            public:true,
          })
            .then(function (response) {
              self.close()
              self.fetchFacilities()
              self.loader = false
              this.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
            })
            .catch(error => {
              self.loader = false
              self.err.push(error)
            })
          // } else {
          //   self.snack = true
          //   self.snackText = 'Error. Please correct form errors.'
          //   self.snackColor = 'red'
          //   return false
          // }
          // })
        }
      },
      updateFacility () {
          const self = this
          // this.$validator.validateAll().then((result) => {
          //   if (result) {
          this.loader = true
          const catData = this.editedItem

          axios.put(this.apiSet.facilities+'/'+catData._id, {
            name: catData.name,
            facilityCategory: catData.facilityCategory,
            company: this.userData.company._id,
            branch: this.userData.branch._id,
            location: this.userData.branch.name,
            public:true,
          })
            .then(function (response) {
              self.close()
              self.fetchFacilities()
              self.loader = false
              this.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
            })
            .catch(error => {
              self.loader = false
              self.err.push(error)
            })
          // } else {
          //   self.snack = true
          //   self.snackText = 'Error. Please correct form errors.'
          //   self.snackColor = 'red'
          //   return false
          // }
          // })
      },

      editItem (item) {
        this.editedIndex = this.facilities.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.facilities.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.facilities.splice(this.editedIndex, 1)
        const self = this
        this.loader = true
        const delId = this.editedItem._id

        axios.delete(this.apiSet.facilities+'/'+delId)
          .then(function (response) {
            self.loader = false
            self.$store.commit('setSnack', { t: 'Success. Data was successfully deleted.', c: 'green darken-3' })
            self.closeDelete()

          })
          .catch(error => {
            self.loader = false
            self.err.push(error)
          })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.facilities[this.editedIndex], this.editedItem)
        } else {
          this.facilities.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>
